/* eslint-disable-next-line no-restricted-imports */
import styled, { css, keyframes } from 'styled-components';

import { COLORS } from '@headout/fresh-aer';

const fadeOutAnimation = keyframes`
  0% {
    opacity: initial;
  }
  100% {
    opacity: 0;
  }
`;

const fadeInCSS = css`
	img {
		animation: ${fadeOutAnimation} 1.6s ease-in-out forwards;
	}
`;

export const VideoContainer = styled.div<{
	$fadeInVideo: boolean;
	$isDesktop: boolean;
	$responsive?: boolean;
	width: number;
	height: number;
}>`
	position: relative;
	display: grid;
	justify-items: end;
	width: 100%;

	img {
		z-index: 1;
		object-fit: cover;
		width: 100%;
		height: 100% !important;
	}

	img,
	video {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
	}

	${({ $fadeInVideo }) => $fadeInVideo && fadeInCSS};

	@media (min-width: 768px) {
		width: ${({ width, $responsive }) =>
			!$responsive && width ? width + 'px' : '100%'};
		height: ${({ height }) => (height ? height + 'px' : '100%')};
	}
`;

export const StyledVideo = styled.video<{
	width: number;
	height: number;
	$responsive?: boolean;
}>`
	width: 100%;
	height: 100%;
	object-fit: cover;
	@media (min-width: 768px) {
		width: ${({ width, $responsive }) =>
			width && !$responsive ? width + 'px' : '100%'};
		height: ${({ height }) => (height ? height + 'px' : '100%')};
	}
`;

export const PlayButton = styled.button`
	border: 0;
	background: ${COLORS.BRAND_COLORS.WHITE};
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	backdrop-filter: blur(2px);
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 2rem;
		height: 2rem;
		fill: ${COLORS.BRAND_COLORS.WHITE};
	}
`;
